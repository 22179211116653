import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/zustand/useAuth';
import useLogStore from '../../hooks/zustand/admin-log/useLogStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleLoginResponse } from '../../lib/api/handleApiResponse';
// components
import { FormProvider, RHFInput, RHFInputAddon } from '../../components/RHForm';
import ButtonLp from '../../components/ButtonLp';
import { enqueueSnackbar } from 'notistack';
import ModalAccountLocked from './Modal/Modal.account-locked';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import useHomeSlider from '../../hooks/zustand/useHomeSlider';

const schemaUsePhone = yup.object().shape({
  phoneNumber: yup
    .string()
    .required('Phone is required')
    .matches(/^\d+$/, 'Number only!')
    .min(9, 'Minimum 9 digit numbers')
    .max(13, 'Maximum 13 digit numbers'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(32, 'Password must be at most 32 characters'),
});

const schemaUseEmail = yup.object().shape({
  email: yup
    .string()
    .email('Email must be a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(32, 'Password must be at most 32 characters'),
});

const defaultValues = {
  phoneNumber: '',
  email: '',
  password: '',
};

const SignIn = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const logStore = useLogStore();
  const homeSliderStore = useHomeSlider();
  const [useEmail, setUseEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const attempt = useLocalStorage('withdrawalAttempt');
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [bgImg, setBgImg ] = useState(process.env.REACT_APP_LOGIN_BG_IMG);
  
  const initialGetBgImg =  async () => {
    const resp = await homeSliderStore.getBgImg();
    if (resp.status === 200) {
      if (resp?.data?.slider?.bg_img ?? '' !== '')
        setBgImg(resp.data.slider.bg_img)
    }
  }

  useEffect(() => {
    if (location.state?.success === true) {
      enqueueSnackbar('Register successfully. You can login!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    navigate({ ...location, state: { success: false } }, { replace: true });

    const currLanguage = params.language ?? process.env.REACT_APP_DEFAULT_LANGUAGE;
    i18next.changeLanguage(params.language);

    initialGetBgImg();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm({
    resolver: yupResolver(useEmail ? schemaUseEmail : schemaUsePhone),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const handleUseLogin = () => {
    setUseEmail(!useEmail);
    reset();
  };

  const onSubmit = async () => {
    const res = await auth.login(
      !useEmail ? values.phoneNumber : values.email,
      values.password,
      !useEmail ? 'phone' : 'email'
    );
    const validated = handleLoginResponse(res, setError);
    if (
      validated &&
      res?.user?.role !== 'admin' &&
      res?.user?.status !== 'lock'
    ) {
      const memberId = !useEmail ? values.phoneNumber : values.email;
      const maxSuspendAttempts = res?.user?.maxSuspendAttempts ?? process.env.REACT_APP_DEFAULT_MAX_SUSPENDED_ATEMPTS;
      if (res?.user?.status === 'suspend' && res?.user?.suspendedAttempts >= maxSuspendAttempts) {
        await logStore.createData({
          adminId:'',
          memberId: memberId,
          log: `${memberId} login unsuccessful due to suspended`,
          payload: values,
          action: 'login',
          module: 'member',
          table: 'users'
        });
        navigate(`/dashboard/withdraw`);
      } else {
        await logStore.createData({
          adminId: '',
          memberId: memberId,
          log: `${memberId} login successfully`,
          payload: values,
          action: 'login',
          module: 'member',
          table: 'users'
        });
        navigate(`/dashboard`);
      }
    }
    if (validated && res.user?.role !== '' && res.user?.status === 'lock') {
      setOpen(true);
      reset();
    }
    auth.setDefaultPassword('');
  };

  return (
    <>
    <div
      className='w-full h-screen relative'
      style={{
        backgroundImage: `url('${bgImg}')`,
        opacity: 0.5,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
    </div>    
    <div className='w-full h-full flex items-center justify-center absolute top-0 left-0 z-10 '>
        <div className='w-full lg:w-[30rem] bg-white overflow-hidden rounded-lg shadow-lg p-5 lg:px-10 py-10 lg:py-14 mx-auto'>
          <div className='flex flex-col items-center'>
            <img
               src={process.env.REACT_APP_LOGO_PATH}
               style={{ 
                width: '50px'
                }}
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className='w-36 lg:w-40 object-contain object-center'
            />
            <h1 className='text-sm lg:text-md font-semibold text-gray-400 text-center my-10'>
              { t("home.signin.newhere") }
              <Link to={`/${i18next.language}/sign-up`} className='text-blue-500'>
                { t("home.signin.createanaccount") }
              </Link>
            </h1>
          </div>
          {auth.defaultPassword !== '' ? (
            <div className='w-full bg-c-green/50 p-3 mb-5 rounded-lg'>
              <p className='text-sm font-semibold'>
                Here's your new password :{' '}
                {auth.defaultPassword ? auth.defaultPassword : null}
              </p>
            </div>
          ) : null}
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 lg:gap-8 w-full mx-auto'>
              {!useEmail ? (
                <div>
                  <div className='flex justify-between items-center mb-2'>
                    <label className='text-sm font-medium text-gray-900'>
                      { t("home.signin.phone") }
                    </label>
                    <button
                      className='text-sm font-medium text-blue-500'
                      type='button'
                      onClick={() => handleUseLogin()}
                    >
                      { t("home.signin.useemaillogin") }
                    </button>
                  </div>
                  <RHFInputAddon name='phoneNumber' addon='+60' />
                </div>
              ) : (
                <div>
                  <div className='flex justify-between items-center mb-2'>
                    <label className='text-sm font-medium text-gray-900'>
                      { t("home.signin.email") }
                    </label>
                    <button
                      className='text-sm font-medium text-blue-500'
                      type='button'
                      onClick={() => handleUseLogin()}
                    >
                      { t("home.signin.usephonelogin")}
                    </button>
                  </div>
                  <RHFInput name='email' />
                </div>
              )}
              <div>
                <div className='flex justify-between items-center mb-2'>
                  <label className='text-sm font-medium text-gray-900'>
                    { t("home.signin.password") }
                  </label>
                  <Link
                    to={`/${i18next.language}/forgot-password`}
                    className='text-sm font-medium text-blue-500'
                    reloadDocument
                  >
                    {t("home.signin.forgetpassword")}
                  </Link>
                </div>
                <RHFInput name='password' type='password' />
              </div>
              <ButtonLp
                type='submit'
                className='w-40 mx-auto mt-5'
                loading={isSubmitting}
              >
                { t("home.signin.signin")}
              </ButtonLp>
            </div>
          </FormProvider>
          <div className='mt-4 text-center'>
            <Link to='/' className='font-semibold text-blue-500 text-sm'>
              { t("home.signin.backtohome") }
            </Link>
          </div>
        </div>
      </div>
      <ModalAccountLocked open={open} close={() => setOpen(false)} />
</>

    
  );
};

export default SignIn;
